<template>
  <v-container>

    <v-row no-gutters>
      <v-text-field v-model="search" outlined rounded label="Search Subscription..."></v-text-field>
      <v-btn @click="addNew = true;isEdit=false;" rounded dark color="black" height="56" elevation="0" class="ml-2">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Add New
      </v-btn>
    </v-row>

    <v-card outlined :loading="loading">
      <v-data-table
          :search="search"
          :headers="headers"
          :items="products">

        <template v-slot:item.image_path="{ item }">
          <v-avatar class="ma-2">
            <v-img :src="$store.state.s3ResourcesBaseUrl + item.image_path"></v-img>
          </v-avatar>
        </template>

        <template v-slot:item.description="{ item }">
          <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.features">
            View
          </v-btn>
        </template>

        <template v-slot:item.status="{ item }">
            <v-chip
              class="ma-2"
              :color=" item.status == 1 ? 'success': 'warning'"
            >
            {{ item.status == 1 ? 'Active' : 'Disabled' }}
            </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="editProduct(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn @click="statusChange(item.id, item.status === 1? 2 : 1)" icon>
              <v-icon :color=" item.status === 2 ? 'success': 'warning'"> {{ item.status === 1 ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
            </v-btn>
          <v-icon @click="showDeleteConfirmation(item, deleteProduct)" color="error">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <span class="headline">Description</span>
            <v-spacer/>
            <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-divider class="mb-4"/>
        <v-list>
            <v-list-item
              v-for="(item, i) in selectedDescription"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <v-bottom-sheet persistent="false" scrollable  v-if="addNew" v-model="addNew" inset hide-overlay >
      <v-card class="rounded-t-lg rounded-b-0">
        <v-card-title>
          <v-row no-gutters>
            {{ isEdit ? 'Edit' : 'Add' }} Subscription
            <v-spacer/>
            <v-btn color="red" icon @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form v-if="!isEdit" ref="form" v-model="valid">
            <v-select  :items="roles" v-model="data.role" class="rounded-lg" item-text="text" item-value="value"  :rules="[rules.required]" outlined label="Select the Role"/>
            <v-text-field v-model="data.title" outlined label="Title" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.duration" outlined label="Duration" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.price" type="number" min="0" outlined label="Price" :rules="[rules.required]"></v-text-field>
            <v-row no-gutters>
              <v-text-field class="rounded-r-0" outlined v-model="facility" label="Features"/>
              <v-btn @click="facility.length > 0 ? data.features.push(facility): nothing(); facility = ''" height="56" elevation="0" color="primary" class="rounded-l-0">
                <v-icon class="mr-2">mdi-plus</v-icon> Add
              </v-btn>
            </v-row>
            <v-row no-gutters justify="start">
              <v-chip dark color="primary" close v-for="(fs,i) in data.features" :key="i" label class="mx-1" @click:close="data.features.splice(data.features.indexOf(fs), 1)">
                {{ fs }}
              </v-chip>
            </v-row>
          </v-form>

          <v-form v-if="isEdit" ref="formEdit" v-model="validEdit">
            <v-select  :items="roles" v-model="data.role" class="rounded-lg" item-text="text" item-value="value"  :rules="[rules.required]" outlined label="Select the Role"/>
            <v-text-field v-model="data.title" outlined label="Title" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.duration" outlined label="Duration" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.price" type="number" min="0" outlined label="Price" :rules="[rules.required]"></v-text-field>
            <v-row no-gutters>
              <v-text-field class="rounded-r-0" outlined v-model="facility" label="Features"/>
              <v-btn @click="facility.length > 0 ? data.features.push(facility): nothing(); facility = ''" height="56" elevation="0" color="primary" class="rounded-l-0">
                <v-icon class="mr-2">mdi-plus</v-icon> Add
              </v-btn>
            </v-row>
            <v-row no-gutters justify="start">
              <v-chip dark color="primary" close v-for="(fs,i) in data.features" :key="i" label class="mx-1" @click:close="data.features.splice(data.features.indexOf(fs), 1)">
                {{ fs }}
              </v-chip>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions style="background: #1976d2">
          <v-spacer/>
          <v-btn v-if="!isEdit" :loading="loading" @click="submitForm" large color="white">
            <v-icon class="mr-2">mdi-check</v-icon>
            Save
          </v-btn>
          <v-btn v-if="isEdit" :loading="loading" @click="saveEdited" large color="white">
            <v-icon class="mr-2">mdi-check</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";

export default {
  name: "PendingGymServices",
  data: () => ({
    rules: {
      required: value => !!value || 'Required.',
    },
    data: {
      id: null,
      features:[],
      title: '',
      role: '',
      duration: '',
      price: 0,
    },
    roles: [
      {text: "Client", value: "client"},
      {text: "Trainer", value: "trainer"},
      {text: "Doctor", value: "doctor"}
    ],
    descriptionViewDialog:false,
    valid: false,
    facility:"",
    validEdit: false,
    image: null,
    search: '',
    loading: true,
    addNew: false,
    isEdit: false,
    exGymList:[],
    headers: [
      {text: "ID", value: "id"},
      {text: "", value: "", sortable: false},
      {text: "Title", value: "title"},
      {text: "Role", value: "role"},
      {text: "Duration", value: "duration"},
      {text: "Price", value: "price"},
      {text: "Features", value: "description"},
      {text: "Status", value: "status"},
      {text: "Actions", value: 'actions', sortable: false}
    ],
    products: [],
    categories: [],
  }),
  methods: {
    resetProduct(){
          this.data = {
            id: null,
            features:[],
            title: '',
            role: '',
            duration: '',
            price: 0,
          };
      },
      statusChange(id, status) {
        authClient.post('/status/change',{
            status: status,
            tableId: 31,
            resultId: id
          }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
          this.getServices()
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          }).catch((error) => {
            console.log(error);
          });
      },
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item.id)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      deleteProduct(item) {
        authClient.post('delete',{
            tableId: 31,
            resultId: item
          }).then((response) => {
            let data = response.data[0]
            if(data.status){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
              });
              this.getServices()

            authClient.post('/users/file-uploads/delete-file',{
            filePath: item.image_path
            }).then((response) => {
            }).catch((error) => {
              console.log(error);
            });
            
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          }
            
          }).catch((error) => {
            console.log(error);
          });
      },
      editProduct(item){
        this.isEdit = true;
        this.addNew = true;
        this.data = item;
      },
      saveEdited(){
        this.$refs.formEdit.validate();
        if(this.validEdit){
          this.loading = true;

          authClient.post('/web-subscription/update', this.data)
          .then((response) => {
              this.close();
              this.loading = false;
              // this.loadData()
              let response_data = response.data[0]
              if(response_data.status){
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
              }
            }).catch((error) => {
              this.loading = false;
            });
      
          }else{
            this.loading = false
          }
      },
      
      submitForm(){
        
        this.$refs.form.validate();
        if(this.valid){
          this.loading = true;

          authClient.post('/web-subscription/add', this.data)
          .then((response) => {
              this.close();
              this.loading = false;
              // this.loadData()
              let response_data = response.data[0]
              if(response_data.status){
                this.$toast.success({
                  title: 'Ok',
                  message: response_data.message,
              });
              }else{
                this.loading = false;
              }
            }).catch((error) => {
              console.log(error)
              this.loading = false;
            });
      
          }else{
            this.loading = false
          }
      },
      close() {
        this.addNew = false;
        this.resetProduct();
        this.getServices();
      },
      getServices() {
        this.loading = true;
        this.products = [];


        authClient.get('/web-subscription/list')
            .then(response => {
              this.loading = false;
              this.products = response.data;
            })
            .catch(error => {
              console.log(error);
              this.loading = false;
            })
      },
  },
  mounted() {
    this.loading = false;
    this.getServices();
  }
}
</script>

<style scoped>

</style>
