import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),(!_vm.loading)?_c(VCol,[_c('UserDetailsHeadingdeprecated',{staticClass:"mb-4",attrs:{"data":_vm.getUserData(),"gym":_vm.gym},on:{"close":_vm.getGymFromServer}}),_c(VDivider,{staticClass:"mb-4"}),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.gym.gym_type === 'normal')?_c('ComGymSubscriptions',{attrs:{"user_id":_vm.gym.user.id}}):_vm._e()],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }