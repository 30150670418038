import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":""}}):_vm._e(),(!_vm.loading)?_c('div',[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCard,{attrs:{"width":"256","height":"128"}},[_c(VCardTitle,[_vm._v("Total Earnings")]),_c(VCardText,[_c('h1',[_vm._v(_vm._s(_vm.getTotalAmounts().toFixed(2))+" MVR")])])],1)],1),_c(VDivider,{staticClass:"my-4"}),_c(VCard,[_c(VDataTable,{attrs:{"items":_vm.records,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', }))+" ")]}},{key:"item.amount",fn:function({ item }){return [_vm._v(" "+_vm._s(item.amount + ' MVR')+" ")]}}],null,false,623024831)})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }