<template>
    <v-container>

      <v-row  class="mb-4">
      <v-card outlined hover ripple dark class="green mr-4 mb-3 mb-md-0" min-width="180" width="auto">
        <v-card-subtitle class="pb-0">Total Tax</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ Total_tax.toFixed(2) }}</v-card-title>
      </v-card>
      <v-card outlined hover ripple dark class="warning mr-4 mb-3 mb-md-0" min-width="180" width="auto">
        <v-card-subtitle class="pb-0">Total Amount</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ Total_amount.toFixed(2) }}</v-card-title>
      </v-card>
    </v-row>
    <v-row gutters>
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
      <div>
    <v-text-field v-model="search" outlined class="rounded-lg" label="Search Members..." append-icon="mdi-magnify" @keyup.enter="getOrders()" @click:append="getOrders()"></v-text-field>
  </div>
      </v-col>
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="rounded-lg"
              outlined
              readonly
              v-model="dateRangeText"
              label="Date Range"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            range
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dates= []"
            >
              Reset
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(date);getOrders()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
            cols="12"
            md="3"
            lg="2"
      >
            <v-btn
            @click="getOrders(1)"
            :style="{ width: '100%' }"
              x-large
              color="warning"
              dark
            :loading="loading"
            >
            <v-icon
              left
              dark
            >
            mdi-tray-arrow-down
            </v-icon>
              Export Pdf
            </v-btn>
      </v-col>
      <v-col
            cols="12"
            md="3"
            lg="2"
      >
            <v-btn
            @click="downloadCsv()"
            :loading="loading"
            :style="{ width: '100%' }"
              x-large
              color="warning"
              dark
            >
            <v-icon
              left
              dark
            >
            mdi-tray-arrow-down
            </v-icon>
              Export Csv
            </v-btn>
      </v-col>
    </v-row>
    <v-row gutters class="mt-0">
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
      <v-select @change="getOrders()" :items="[ {'name':'ALL', 'id': null} ,...products]" v-model="selected_product" class="rounded-lg" item-text="name" item-value="id"  outlined label="Product"/>
      </v-col>
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
      <v-select @change="getOrders()" :items="[ {'name':'ALL', 'id': null} ,...categories]" v-model="selected_category" class="rounded-lg" item-text="name" item-value="id"  outlined label="Category"/>
      </v-col>
    </v-row>


      <v-card outlined>
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="stories">

        <template v-slot:item.prouct.image_path="{ item }">
          <v-avatar class="ma-2">
            <v-img :src="$store.state.s3ResourcesBaseUrl + item.prouct.image_path"></v-img>
          </v-avatar>
        </template>

        <template v-slot:item.created_at="{ item }">
            {{  new Date(item.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                })
              }}
        </template>

          <template v-slot:item.prouct.description="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.prouct.description">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.gender="{ item }">
            <v-btn  @click="optionalViewDialog=true;basicInfo = item" outlined rounded>
              View
            </v-btn>
          </template>
  
        </v-data-table>
      </v-card>

  
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Description</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            {{ selectedDescription }}
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog max-width="768" persistent v-if="optionalViewDialog" v-model="optionalViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Basic Info</span>
              <v-spacer/>
              <v-btn @click="optionalViewDialog = false; basicInfo=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text class="basic-data">
            <template>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Country
                      </th>
                      <th class="text-left">
                        City
                      </th>
                      <th class="text-left">
                        Address
                      </th>
                      <th class="text-left">
                        Gender
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                    >
                      <td>{{ basicInfo.country }}</td>
                      <td>{{ basicInfo.city }}</td>
                      <td>{{ basicInfo.address }}</td>
                      <td>{{ basicInfo.gender == '1'? 'Male':'Female' }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import Papa from 'papaparse';
  import SuccessStoryDialog from "@/components/SuccessStoryDialog.vue";
  
  export default {
    name: "ProductOrders",
    components: {SuccessStoryDialog},
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    data: () => ({
      loading: true,
      domain:'',
      addStoryDialog: false,
      descriptionViewDialog:false,
      optionalViewDialog:false,
      selectedOptional: null,
      selectedDescription: null,
      selected_product:null,
      selected_category:null,
      date:null,
      menu: false,
      modal: false,
      menu2: false,
      dates: [],
      basicInfo: null,
      isEdit: false,
      editStory: null,
      stories: [],
      headers: [
        {text: "", value: "prouct.image_path", sortable: false},
        {text: "Transaction Date & Time", value: "created_at"},
        {text: "User", value: "buyer.name"},
        {text: "Email", value: "buyer.email"},
        {text: "Product", value: "prouct.name"},
        {text: "Qty", value: "quantity"},
        {text: "Amount", value: "amount"},
        {text: "Tax", value: "tax"},
        {text: "Total", value: "total_amount"},
        {text: "Description", value: "prouct.description"}
      ],
      search: '',
    }),
    props: {
      categories: {
        type: Array,
        required: true
      },products: {
        type: Array,
        required: true
      },Total_tax: {
        type: Number,
        required: true
      }
      ,Total_amount: {
        type: Number,
        required: true
      }
    },
    methods: {
      downloadCsv() {
        // Replace this with your actual CSV data
        const csvData = this.stories.map(item => ({
          "Transaction Date & Time": item.created_at,
          User: item.buyer.name,
          Email: item.buyer.email,
          Product: item.prouct.name,
          Qty: item.quantity,
          Amount: item.amount,
          Tax: item.tax,
          Total: item.total_amount,
          Description: item.prouct.description,
        }));;

        const csv = Papa.unparse(csvData);

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${Date.now()}_ecommerce_insights.csv`;
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
      getOrders(type=0) {
        this.loading = true;
        authClient.post('/ecom/products/user-orders',{
          'dateRange':this.dates,
          'product_id' : this.selected_product,
          'category_id' : this.selected_category,
          'search' : this.search,
          'type' : type,
        }, {responseType:  type == 0? '':'blob'}).then(response => {

          if(type == '1'){

            const href = window.URL.createObjectURL(response.data);

            const anchorElement = document.createElement('a');

            anchorElement.href = href;
            anchorElement.download = `${Date.now()}_ecommerce_insights.pdf`;
            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);

          }else{
            this.stories = response.data;
          
          }
          this.loading = false;
        });
     },
      closeDialogs() {
        this.addStoryDialog = false;
        this.getOrders();
      }
    },
    mounted() {
      this.getOrders();
    }
  }
  </script>
  
  <style scoped>
    
    .basic-data{
        font-size: medium;
        color: black !important;
    }

  </style>
  