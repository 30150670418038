import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticStyle:{"height":"100% !important"}},[_c(VCol,{staticStyle:{"height":"100%","display":"flex","flex-wrap":"nowrap","align-content":"center","justify-content":"center","align-items":"center"}},[_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCard,{staticClass:"rounded-lg",attrs:{"elevation":"24","width":"380"}},[_c(VImg,{staticClass:"ma-8",attrs:{"contain":"","src":require("@/assets/allblack.png")}}),_c(VCardTitle,{staticClass:"text-h4"},[_vm._v("Sign In")]),_c(VCardText,{staticClass:"mb-0 pb-0"},[_c(VForm,[_c(VTextField,{attrs:{"type":"text","outlined":"","label":"Email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"type":"password","outlined":"","label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VCardActions,{staticClass:"mx-2 mb-4 mt-0 pt-0"},[_c(VSpacer),_c(VBtn,{staticClass:"rounded-lg",attrs:{"loading":_vm.loading,"large":"","width":"128","color":"primary"},on:{"click":_vm.signIn}},[_vm._v("Sign In")])],1),(_vm.hasError)?_c(VCardText,[_c(VRow,{attrs:{"justify":"center"}},[_c('span',{staticClass:"red--text mb-4 text-h4"},[_vm._v("Unauthorized!")])])],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }