<template>
  <div>
    <v-row align="center" no-gutters>
         
          <v-img  :src="image_src_1"
                class="img-properties" alt="" max-height="128" min-width="128" min-height="128" max-width="128"></v-img>
                <input  ref="imageUploadFeild_1" type="file" accept='image/*'
                v-on:change="viewImage( $event)" class="d-none" >
    

      <v-img v-if="data.role != 'gym'" class="rounded-xl" max-height="128" min-width="128" min-height="128" max-width="128" :src=" $store.state.s3BaseUrl + data.avatar_url"></v-img>
      <v-col class="ml-8">
        <span class="text-h4 font-weight-bold">{{ data.name }}</span><br>
        <v-chip v-if="(data.role !=='doctor') && (data.role !=='gym')" dark
                :color="checkActiveSub ? '#ffa200':''">
          {{ checkActiveSub  ? 'PRO Member':'Member' }}
        </v-chip>
        <v-chip v-if="data.role ==='doctor'" dark :color="data.doctor.online ? 'green':''">{{ data.doctor.online  ? 'Online':'Offline' }}</v-chip>
      </v-col>



      <v-spacer/>

      

    </v-row>

  </div>
</template>

<script>
import UserEditBasicData from "@/components/UserEditBasicData";
import GymUserEditBasicData from "@/components/GymEditBasicData";
import AddSubscription from "@/components/AddSubscription";
import EditDoctorData from "@/components/EditDoctorData";
import {authClient} from "@/plugins/http";

export default {
  name: "UserDetailsHeadingdeprecated",
  components: {GymUserEditBasicData, UserEditBasicData, AddSubscription, EditDoctorData},
  computed : {
    checkActiveSub(){
      if(this.subscription == null){
          return false;
        } else {
          return this.subscription.is_active
        }
    }
  },
  data: () => ({
    hasActiveSub: false,
    doc_data:{},
    image_src_1: "",
    current_file_img_1: null,
    editSheet: false,
    addProUser: false,
    editGymSheet: false,
    editDoctorSheet: false,
  }),
  props: {
    data: {
      type: Object,
      required: true
    },
    gym: {
      type: Object
    },
    subscription: {
      type: Object,
      required: true
    },
  },
  methods:{
    close(){
      this.editDoctorSheet = false;
      this.editGymSheet = false;
      this.$emit('close');
    },
    imgUpload(type = 1) {
            this.$refs.imageUploadFeild_1.click();
        },
        viewImage(event,type = 1) {
          if (event) {
              this.image_src_1 = URL.createObjectURL(event.target.files[0]);
              this.current_file_img_1 = event.target.files[0]
              this.submitForm()
          }
          
        },
        submitForm(){
            this.loading = true;
            let formData = new FormData();
            formData.append('file', this.current_file_img_1);
            formData.append('userId', this.data.id);
            authClient.post('/users/file-uploads/avatar',formData)
            .then((response) => {
                this.loading = false;
                // this.loadData()
                let response_data = response.status
                if(response_data){
                  this.$toast.success({
                    title: 'Ok',
                    message: 'success',
                });
                }else{
                  this.loading = false;
                  this.$toast.error({
                    title: 'error',
                });
                }
              }).catch((error) => {
                console.log(error)
                this.loading = false;
                this.$toast.error({
                    title: 'error',
                    message: 'error',
                });
              });
        },
  },
  mounted() {
    this.doc_data = Object.assign({}, this.data );;
    if(this.data.role == 'gym'){
      this.image_src_1= this.$store.state.s3BaseUrl + this.data.avatar_url
    }
  }
}
</script>

<style scoped>

.img-properties {
        height: auto;
        position: relative;
        z-index: 0;
        border-radius: 0.25rem;
        margin: 0 auto;
        display: block;
        max-height: 400px;
        margin-top: 10px;
      }
  
      .img-container-1{
        position: relative;
      }
      .dp_change{
        background-color: #BBDEFB;
        border-radius: 10px;
      }
  
      .upload-lable{
        position: absolute;
        top: 1.5rem; /* You can adjust the top margin as needed */
        margin-left: 0.5rem; /* You can adjust the left margin as needed */
        z-index: 1;
      }

</style>
