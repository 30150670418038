<template>
    <v-container>
        <v-row  class="mb-4">
            <v-card outlined hover ripple dark class="green mr-4 mb-3 mb-md-0" width="180">
                <v-card-subtitle class="pb-0">Total users</v-card-subtitle>
                <v-card-title class="pt-0 text-h4">{{ TotalUsers }}</v-card-title>
            </v-card>
            <v-card outlined hover ripple dark class="warning mr-4 mb-3 mb-md-0" min-width="180" width="auto">
                <v-card-subtitle class="pb-0">Total Credit Amount</v-card-subtitle>
                <v-card-title class="pt-0 text-h4">{{ TotalCreditAmount.toFixed(2) }}</v-card-title>
            </v-card>
            <v-card outlined hover ripple dark class="warning mr-4 mb-3 mb-md-0" min-width="180" width="auto">
                <v-card-subtitle class="pb-0">Total Debit Amount</v-card-subtitle>
                <v-card-title class="pt-0 text-h4">{{ TotalDebitAmount.toFixed(2)  }}</v-card-title>
            </v-card>
        </v-row>
        <v-row gutters>
            <v-col
                    class="pb-0"
                    cols="12"
                    md="6"
                    lg="4"
            >
            <div>
            <v-text-field v-model="search" outlined class="rounded-lg" label="Search User..." append-icon="mdi-magnify" @keyup.enter="getTopups()" @click:append="getTopups()"></v-text-field>
        </div>
            </v-col>
            <v-col
                    class="pb-0"
                    cols="12"
                    md="6"
                    lg="4"
            >
                <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    class="rounded-lg"
                    outlined
                    readonly
                    v-model="dateRangeText"
                    label="Date Range"
                    v-bind="attrs"
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="dates"
                    range
                >
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    color="primary"
                    @click="dates= []"
                    >
                    Reset
                    </v-btn>
                    <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                    >
                    Cancel
                    </v-btn>
                    <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date);getTopups()"
                    >
                    OK
                    </v-btn>
                </v-date-picker>
                </v-dialog>
            </v-col>
            <v-col
                    cols="12"
                    md="3"
                    lg="2"
            >
                    <v-btn
                    @click="getTopups(1)"
                    :style="{ width: '100%' }"
                    x-large
                    color="warning"
                    dark
                    :loading="loading"
                    >
                    <v-icon
                    left
                    dark
                    >
                    mdi-tray-arrow-down
                    </v-icon>
                    Export Pdf
                    </v-btn>
            </v-col>
            <v-col
                    cols="12"
                    md="3"
                    lg="2"
            >
                    <v-btn
                    @click="downloadCsv()"
                    :loading="loading"
                    :style="{ width: '100%' }"
                    x-large
                    color="warning"
                    dark
                    >
                    <v-icon
                    left
                    dark
                    >
                    mdi-tray-arrow-down
                    </v-icon>
                    Export Csv
                    </v-btn>
            </v-col>
        </v-row>
        <v-row gutters class="mt-0">
            <v-col
                    class="pb-0"
                    cols="12"
                    md="6"
                    lg="4"
            >
            <v-select @change="getTopups()" :items="topUpTypes" v-model="topUpType" class="rounded-lg" item-text="text" item-value="value"  outlined label="Type"/>
            </v-col>
        </v-row>
      <v-card outlined>
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="AdmintopUps">
          <template v-slot:item.description="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.description">
              View
            </v-btn>
          </template>
  
          <template v-slot:item.created_at="{ item }">
            {{  new Date(item.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                })
              }}
          </template>
  
          <template v-slot:item.ad_url="{ item }">
            <v-btn outlined rounded target="_blank" :href="item.ad_url">
              Visit
            </v-btn>
          </template>

  
          <template v-slot:item.actions="{ item }">
            <v-btn @click="isEdit = true; courtesy_Notes = item; addStoryDialog = true" icon >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="showDeleteConfirmation(item, deleteItem)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
  
      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Description</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            {{ selectedDescription }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import Papa from 'papaparse';
  
  export default {
    name: "ReportEwallet",
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    data: () => ({
      loading: true,
      date:null,
      TotalUsers:0,
      TotalCreditAmount:0,
      TotalDebitAmount:0,
      menu: false,
      modal: false,
      menu2: false,
      topUpType:'0',
      topUpTypes: [
        {text: 'All', value: '0'},
        {text: 'Credit', value: '1'},
        {text: 'Debit', value: '2'}
      ],
      dates: [],
      domain:'',
      orderList:[],
      addStoryDialog: false,
      descriptionViewDialog:false,
      optionalViewDialog:false,
      selectedOptional: null,
      selectedDescription: null,
      isEdit: false,
      courtesy_Notes: null,
      AdmintopUps: [],
      headers: [
        {text: "Transcation Date & Time", value: "created_at"},
        {text: "User", value: "user.name"},
        {text: "Role", value: "user.role"},
        {text: "Email", value: "user.email"},
        {text: "Amount", value: "amount"},
        {text: "Type", value: "type"},
        {text: "Description", value: "description"},
      ],
      search: '',
    }),
    methods: {
      getTopups(type=0) {
        this.loading = true;
        authClient.post('/wallet/admin/topup/list',{
          'dateRange':this.dates,
          'topUpType' : this.topUpType,
          'search' : this.search,
          'type' : type,
        }, {responseType:  type == 0? '':'blob'}).then(response => {

          if(type == '1'){

            const href = window.URL.createObjectURL(response.data);

            const anchorElement = document.createElement('a');

            anchorElement.href = href;
            anchorElement.download = `${Date.now()}_topup_insights.pdf`;
            document.body.appendChild(anchorElement);
            anchorElement.click();

            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(href);

          }else{
            this.AdmintopUps = response.data;
          
          }
          this.loading = false;
        });
      },
      getCounts() {
        authClient.get('/wallet/admin/topup/count').then(response => {
          this.TotalUsers = response.data.user_count
          this.TotalCreditAmount = response.data.credit_count
          this.TotalDebitAmount = response.data.debit_count
        });
      },
      downloadCsv() {
      // Replace this with your actual CSV data
      const csvData = this.AdmintopUps.map(item => ({
        'Transcation Date & Time': item.created_at,
        'User': item.user.name,
        'Role': item.user.role,
        'Email': item.user.email,
        'Amount': item.amount,
        'Type': item.type,
        'Description': item.description,
      }));;

      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = `${Date.now()}_egift_insights.csv`;
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
    },
    mounted() {
      this.getTopups();
      this.getCounts()
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  