import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,{attrs:{"align":"center","no-gutters":""}},[_c(VImg,{staticClass:"img-properties",attrs:{"src":_vm.image_src_1,"alt":"","max-height":"128","min-width":"128","min-height":"128","max-width":"128"}}),_c('input',{ref:"imageUploadFeild_1",staticClass:"d-none",attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.viewImage( $event)}}}),(_vm.data.role != 'gym')?_c(VImg,{staticClass:"rounded-xl",attrs:{"max-height":"128","min-width":"128","min-height":"128","max-width":"128","src":_vm.$store.state.s3BaseUrl + _vm.data.avatar_url}}):_vm._e(),_c(VCol,{staticClass:"ml-8"},[_c('span',{staticClass:"text-h4 font-weight-bold"},[_vm._v(_vm._s(_vm.data.name))]),_c('br'),((_vm.data.role !=='doctor') && (_vm.data.role !=='gym'))?_c(VChip,{attrs:{"dark":"","color":_vm.checkActiveSub ? '#ffa200':''}},[_vm._v(" "+_vm._s(_vm.checkActiveSub ? 'PRO Member':'Member')+" ")]):_vm._e(),(_vm.data.role ==='doctor')?_c(VChip,{attrs:{"dark":"","color":_vm.data.doctor.online ? 'green':''}},[_vm._v(_vm._s(_vm.data.doctor.online ? 'Online':'Offline'))]):_vm._e()],1),_c(VSpacer)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }