import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '@/views/Home.vue'
import Auth from "@/auth/Auth";
import FoodData from "@/views/FoodData";
import ExerciseBank from "@/views/ExerciseBank";
import Resources from "@/views/Resources";
import AddResourceView from "@/components/AddResourceView";
import Doctors from "@/views/Doctors";
import DoctorDetails from "@/views/DoctorDetails";
import Members from "@/views/Members";
import MemberDetails from "@/views/MemberDetails";
import Trainers from "@/views/Trainers";
import TrainerDetails from "@/views/TrainerDetails";
import ExclusiveGyms from "@/views/ExclusiveGyms";
import CommercialGyms from "@/views/CommercialGyms";
import GymDetails from "@/components/GymDetails";
import ShopManager from "@/views/ShopManager";
import ShopCategoryManager from "@/views/ShopCategoryManager";
import NewDoctors from "@/views/NewDoctors";
import SubscriptionsManager from "@/views/SubscriptionsManager";
import ManageUsers from "@/views/ManageUsers.vue";
import Newsletters from "@/views/Newsletters.vue";
import AddNewsletterView from "@/components/AddNewsletterView.vue";
import AdsManager from "@/views/AdsManager.vue";
import GymFinances from "@/components/GymFinances.vue";
import HomeSettings from "@/views/HompePageSettings.vue";
import SuccessStory from "@/views/SuccessStory.vue";
import SiteAd from "@/views/SiteAd.vue";
import PreviousProject from "@/views/PreviousProject.vue";
import ManageTherapy from "@/views/MangeTherapy.vue";
import TaxMasterManage from "@/views/TaxMasterManage.vue";
import TherapyProfile from "@/views/TherapyProfile.vue";
import TherapyHoldUser from "@/views/TherapyHoldUser.vue";
import TherapyMeeting from "@/views/TherapyMeeting.vue";
import GymServicesManage from "@/views/GymServicesManage.vue";
import PendingGymServices from "@/views/PendingGymServices.vue";
import ApproveService from "@/views/ApproveService.vue";
import CoupnCreation from "@/views/CoupnCreation.vue";
import CourtesyNote from "@/views/CourtesyNote.vue";
import TrainerAppointments from "@/views/TrainerAppointments.vue";
import ComGymPlans from "@/views/ComGymPlans.vue";
import ClientWidget from "@/views/ClientWidget.vue";
import TrainerWidget from "@/views/TrainerWidget.vue";
import CustomNotification from "@/views/CustomNotification.vue";
import ComGymUsers from "@/views/ComGymUsers.vue";
import PresetManage from "@/views/PresetManage.vue";
import ClientMeal from "@/views/ClientMeal.vue";
import GymMemberDetails from "@/views/GymMemberDetails.vue";
import UserWorkouts from "@/views/UserWorkouts.vue";
import ClientWorkouts from "@/views/ClientWorkouts.vue";
import GymExcersiceBank from "@/views/GymExcersiceBank.vue";
import ReportEwallet from "@/views/ReportEwallet.vue";
import ProductOrders from "@/views/ProductOrders.vue";
import FaqMainCategory from "@/views/FaqMainCategory.vue";
import FaqSubCategory from "@/views/FaqSubCategory.vue";
import UserFaq from "@/views/UserFaq.vue";
import MemberSubscription from "@/views/MemberSubscription.vue";
import TrainerSubscriptions from "@/views/TrainerSubscriptions.vue";
import ReportDoctorRevenue from "@/views/ReportDoctorRevenue.vue";
import ReportSingleDoctor from "@/views/ReportSingleDoctor.vue";
import ReportTheraphyRevenue from "@/views/ReportTheraphyRevenue.vue";
import ReportSingleTherapy from "@/views/ReportSingleTherapy.vue";
import ExpenceManage from "@/views/ExpenceManage.vue";
import ExpenseCategory from "@/views/ExpenseCategory.vue";
import HolidayManage from "@/views/HolidayManage.vue";
import CalanderView from "@/views/CalanderView.vue";
import LossProfitView from "@/views/LossProfitView.vue";
import member_insights from "@/views/member_insights.vue";
import CoachRevenue from "@/views/CoachRevenue.vue";
import ExclusiveGymSummery from "@/views/ExclusiveGymSummery.vue";
import SingleExclusiveGymSummery from "@/views/SingleExclusiveGymSummery.vue";
import GymServiceSummery from "@/views/GymServiceSummery.vue";
import ComGymSubscriptions from "@/views/ComGymSubscriptions.vue";
import SingleComgymSummery from "@/views/SingleComgymSummery.vue";
import TrainerClasses from "@/views/TrainerClasses.vue";
import VendorOrders from "@/views/VendorOrders.vue";
import vendorImageManage from "@/views/vendorImageManage.vue";
import GymAccess from "@/views/GymAccess.vue";
import SideBarMenuAccess from "@/views/SideBarMenuAccess.vue";
import WebSubscriptionsManage from "@/views/WebSubscriptionsManage.vue";


//deprecated
import GymDetailsView from "@/components/GymDetailsView";

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/auth', name: 'Auth', component: Auth },
  { path: '/members', name: 'Members', component: Members },
  { path: '/members/member-details', name: 'Member Profile Overview', component: MemberDetails, props: true },
  { path: '/trainers', name: 'Trainers', component: Trainers },
  { path: '/trainers/trainer-details', name: 'Trainer Profile Overview', component: TrainerDetails, props: true },
  { path: '/doctors', name: 'Medical Professionals', component: Doctors},
  { path: '/doctors/doctor-details', name: 'Medical Professionals Profile Overview', component: DoctorDetails, props: true},
  { path: '/doctors/new-doctors', name: 'New Medical Professionals', component: NewDoctors},
  { path: '/food-data', name: 'Food Data', component: FoodData },
  { path: '/exercise-bank', name: 'Exercise Bank', component: ExerciseBank },
  { path: '/resources', name: 'Resources', component: Resources },
  { path: '/resources/add-resources', name: 'Add Resource', component: AddResourceView, props: true },
  { path: '/newsletters', name: 'Newsletters', component: Newsletters },
  { path: '/newsletters/add-newsletter', name: 'Add Newsletter', component: AddNewsletterView, props: true },
  { path: '/exclusive-gyms', name: 'Exclusive Gyms', component: ExclusiveGyms},
  { path: '/commercial-gyms', name: 'Commercial Gyms', component: CommercialGyms},
  { path: '/gyms/:id/details', name: 'Gym Overview', component: GymDetails, props: true},
  { path: '/gyms/:id/finances', name: 'Gym Finances', component: GymFinances, props: true},
  { path: '/shop', name: 'Shop', component: ShopManager},
  { path: '/shop/categories', name: 'Shop Categories', component: ShopCategoryManager},
  { path: '/shop/orders', name: 'Orders', component: GymDetails, props: true},
  { path: '/subscriptions', name: 'Subscriptions', component: SubscriptionsManager},
  { path: '/ads-gallery', name: 'Ads Gallery', component: AdsManager},
  { path: '/manage-users', name: 'Manage Users', component: ManageUsers},
  { path: '/home-settings', name: 'Breadcrumb Manage', component: HomeSettings},
  { path: '/success-story', name: 'Success Story Manage', component: SuccessStory},
  { path: '/site-ads', name: 'Site Ads Manage', component: SiteAd},
  { path: '/previous-projects', name: 'Previous Project Manage', component: PreviousProject},
  { path: '/therapy-manage', name: 'Physio Therapy Manage', component: ManageTherapy},
  { path: '/tax-manage', name: 'Tax Master Manage', component: TaxMasterManage},
  { path: '/therapy-profile', name: 'Therapy Profile', component: TherapyProfile,beforeEnter: (to, from, next) => {
        therapyGuard(next)
      }
    },
    { path: '/user-hold', name: 'User Hold', component: TherapyHoldUser,beforeEnter: (to, from, next) => {

          therapyGuard(next)
        }
    },{ path: '/therapy-meetings', name: 'Therapy Meetings Manage', component: TherapyMeeting,beforeEnter: (to, from, next) => {

          therapyGuard(next)
        }
    },
    { path: '/gym-services', name: 'Gym Services Manage', component: GymServicesManage,beforeEnter: (to, from, next) => {

        gymGuard(next)
        }
    },{ path: '/gym-services/manage', name: 'Manage GYM Services', component: PendingGymServices,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/approve/gym-services', name: 'Approve Gym Service', component: ApproveService,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/coupon-creation', name: 'Coupon Manage', component: CoupnCreation,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/courtesy-note', name: 'Courtesy Note Manage', component: CourtesyNote,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/appointments', name: 'Trainer Appointments', component: TrainerAppointments
    },{ path: '/com-gyms/plans', name: 'Commercial Gym Plans Manage', component: ComGymPlans,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/client-widgets', name: 'Client Widgets Manage', component: ClientWidget,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/trainer-widgets', name: 'Trainer Widgets Manage', component: TrainerWidget,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/custom-notification', name: 'Custom Notification', component: CustomNotification,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/gym-user-manage', name: 'Commercial Gym User Manage', props: true, component: ComGymUsers,beforeEnter: (to, from, next) => {

      gymGuard(next)
        }
    },{ path: '/gyms/:id/preset-manage', name: 'Workout  Preset Manage', component: PresetManage,beforeEnter: (to, from, next) => {

      gymGuard(next)
        }
    },{ path: '/admin/preset-manage', name: 'Admin Workout  Preset Manage', component: PresetManage,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/client-meal', name: 'Client Meal Manage', component: ClientMeal,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/gyms/:id/user-details', name: 'Gym Member Details',  component: GymMemberDetails,beforeEnter: (to, from, next) => {

      gymGuard(next)
        },props: true,
    },{ path: '/gyms/:id/user-workouts', name: 'Gym Member Workouts',  component: UserWorkouts,beforeEnter: (to, from, next) => {

      gymGuard(next)
        },props: true,
    },{ path: '/gyms/client-workouts', name: 'Client Workouts',  component: ClientWorkouts,beforeEnter: (to, from, next) => {

      gymGuard(next)
        },props: true,
    },{ path: '/gyms/exercise-bank', name: 'Gym Exercise Bank',  component: GymExcersiceBank,beforeEnter: (to, from, next) => {

      gymGuard(next)
        },props: true,
    },{ path: '/ewallet-report', name: 'E gift Topup Report', component: ReportEwallet,beforeEnter: (to, from, next) => {

      adminGuard(next)
        }
    },{ path: '/product-orders', name: 'Product Orders', component: ProductOrders,beforeEnter: (to, from, next) => {

      adminGuard(next)
        },props: true,
    },{ path: '/faq/main', name: 'F&Q Main Category', component: FaqMainCategory,beforeEnter: (to, from, next) => {

      adminGuard(next)
        },props: true,
    },{ path: '/faq/sub', name: 'F&Q Sub Category', component: FaqSubCategory,beforeEnter: (to, from, next) => {

      adminGuard(next)
        },props: true,
    },
    { path: '/user-faqs', name: 'User Questions', component: UserFaq,beforeEnter: (to, from, next) => {

      adminGuard(next)
        },props: true,
    },{ path: '/member-subscriptions', name: 'Subscriptions Revenue Of Clients', component: MemberSubscription,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },
    { path: '/trainer-subscriptions', name: 'Subscriptions Revenue Of Coaches', component: TrainerSubscriptions,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/doctor-revenue', name: 'Total Revenue of Medical Professionals', component: ReportDoctorRevenue,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/single/doctor-revenue', name: 'Single Medical Professionals', component: ReportSingleDoctor,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/therapy-revenue', name: 'Total Revenue of Psychotherapy', component: ReportTheraphyRevenue,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/single/therapy-revenue', name: 'Single of Psychotherapy', component: ReportSingleTherapy,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/expenses/manage', name: 'Manage Expences', component: ExpenceManage,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/expense/category', name: 'Manage Expense Category', component: ExpenseCategory,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/holiday/manage', name: 'Manage Holidays', component: HolidayManage,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/holiday-calender', name: 'Holiday Calender', component: CalanderView,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/lossProfit-view', name: 'Profit & Loss', component: LossProfitView,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/member/insights', name: 'Total Revenue Of Members', component: member_insights,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/coaches/insights', name: 'Total Revenue Of Coaches', component: CoachRevenue,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/exclusive_gym/summery', name: 'Exclusive Gym Summery', component: ExclusiveGymSummery ,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/single-exclusive_gym/summery', name: 'Single Exclusive Gym Summery', component: SingleExclusiveGymSummery ,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/single-exclusive-service/summery', name: 'Single Exclusive Gym Service Summery', component: GymServiceSummery ,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/com-gym/summery', name: 'Commercial Gym Subscription Summery', component: ComGymSubscriptions ,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/single-com-gym/summery', name: 'Single Commercial Gym Subscription Summery', component: SingleComgymSummery ,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/trainer-classes', name: 'Trianer Classes', component: TrainerClasses ,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/vendor-orders', name: 'Vendor Machine Transactions', component: VendorOrders ,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/vendor-image', name: 'Vendor Images Mange', component: vendorImageManage ,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/gym-access-log', name: 'Gym Access Log', component: GymAccess ,beforeEnter: (to, from, next) => {
      adminGuard(next)
        },props: true,
    },{ path: '/sidbar/access', name: 'Side Bar Access Control', component: SideBarMenuAccess,beforeEnter: (to, from, next) => {

      adminGuard(next)
        },props: true,
    },{ path: '/web-subscriptions', name: 'Web Subscription Manage', component: WebSubscriptionsManage,beforeEnter: (to, from, next) => {

      moderatorGuard(next)
        },props: true,
    },
    { path: '/gyms/:id/details-view', name: 'Gym Overview', component: GymDetailsView, props: true},
]

function therapyGuard(next){
  let current_user = window.localStorage.getItem('user')
        if( current_user !== null){
          try {
            if (['therapy'].includes( JSON.parse(current_user).role)) {
              next(); // Allow access
            } else {
              store.commit('deAuth')
              next('/auth'); // Redirect to the auth page
            }
          } catch (error) {
            store.commit('deAuth')
            next('/auth');
          }

        }else{
          store.commit('deAuth')
          next('/auth')
        }
}

function gymGuard(next){
  let current_user = window.localStorage.getItem('user')
        if( current_user !== null){
          try {
            if (['gym'].includes( JSON.parse(current_user).role)) {
              next(); // Allow access
            } else {
              store.commit('deAuth')
              next('/auth'); // Redirect to the auth page
            }
          } catch (error) {
            store.commit('deAuth')
            next('/auth');
          }

        }else{
          store.commit('deAuth')
          next('/auth')
        }
}

function adminGuard(next){
  let current_user = window.localStorage.getItem('user')
        if( current_user !== null){
          try {
            
            if (['admin'].includes( JSON.parse(current_user).role)) {
              next(); // Allow access
            } else {
              store.commit('deAuth')
              next('/auth'); // Redirect to the auth page
            }
          } catch (error) {
            store.commit('deAuth')
            next('/auth');
          }

        }else{
          store.commit('deAuth')
          next('/auth')
        }
}

function moderatorGuard(next){
  let current_user = window.localStorage.getItem('user')
        if( current_user !== null){
          try {
            
            if (['moderator'].includes( JSON.parse(current_user).role)) {
              next(); // Allow access
            } else {
              store.commit('deAuth')
              next('/auth'); // Redirect to the auth page
            }
          } catch (error) {
            store.commit('deAuth')
            next('/auth');
          }

        }else{
          store.commit('deAuth')
          next('/auth')
        }
}

const router = new VueRouter({
  routes,
});

export default router
