<template>
    <v-container>
    <v-row  class="pt-4 pl-2">
      <v-card outlined hover ripple dark class="green mr-4 mb-3 mb-md-0" min-width="180" width="auto">
        <v-card-subtitle class="pb-0">Total Amount</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ total_profit.toFixed(2) }}</v-card-title>
      </v-card>
    </v-row>
    <v-row gutters>
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
      <div>
    <v-text-field v-model="search" outlined class="rounded-lg" label="Search Members..." append-icon="mdi-magnify" @keyup.enter="getExpences()" @click:append="getExpences()"></v-text-field>
  </div>
      </v-col>
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="rounded-lg"
              outlined
              readonly
              v-model="dateRangeText"
              label="Date Range"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            range
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dates= []"
            >
              Reset
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(date);getExpences()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
            cols="12"
            md="3"
            lg="2"
      >
            <v-btn
            @click="getExpences(1)"
            :style="{ width: '100%' }"
              x-large
              color="warning"
              dark
            :loading="loading"
            >
            <v-icon
              left
              dark
            >
            mdi-tray-arrow-down
            </v-icon>
              Export Pdf
            </v-btn>
      </v-col>
      <v-col
            cols="12"
            md="3"
            lg="2"
      >
            <v-btn
            @click="downloadCsv()"
            :loading="loading"
            :style="{ width: '100%' }"
              x-large
              color="warning"
              dark
            >
            <v-icon
              left
              dark
            >
            mdi-tray-arrow-down
            </v-icon>
              Export Csv
            </v-btn>
      </v-col>
    </v-row>
      <v-card outlined>
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="expences">

          <template v-slot:item.description="{ item }">
            <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.description">
              View
            </v-btn>
          </template>

          <template v-slot:item.created_at="{ item }">
              {{  new Date(item.created_at).toLocaleString('en-US', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  })
                }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn @click="isEdit = true; editFood = item; addFoodDialog = true" icon>
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="deleteFood(item.id)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
  
      <v-dialog max-width="768" persistent v-if="addFoodDialog" v-model="addFoodDialog">
        <AddExpenceDialog :food='editFood' :categories="categories" :edit="isEdit" @close="closeDialogs"/>
      </v-dialog>

      <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
        <v-card>
          <v-card-title>
            <v-row no-gutters>
              <span class="headline">Description</span>
              <v-spacer/>
              <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-card-title>
          <v-divider class="mb-4"/>
          <v-card-text>
            {{ selectedDescription }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import {authClient} from "@/plugins/http";
  import Papa from 'papaparse';
  import AddExpenceDialog from "@/components/AddExpenceDialog";
  
  export default {
    name: "CoachRevenue",
    components: {AddExpenceDialog},
    data: () => ({
      total_profit:0,
      total_loss:0,
      loading: true,
      addFoodDialog: false,
      isEdit: false,
      editFood: null,
      total_tax:0,
      total_amount:0,
      date:null,
      dates:[],
      menu: false,
      modal: false,
      menu2: false,
      categories:[],
      descriptionViewDialog:false,
      expences:[],
      foods: [],
      headers: [
        {text: "Transaction Date & Time", value: "created_at"},
        {text: "Name", value: "user.name"},
        {text: "Amount", value: "amount"},
        {text: "Service", value: "name"},
        {text: "Description", value: "description"}
      ],
      search: '',
    }),
    computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
    methods: {
        getExpences(type=0) {
          this.loading = true;
          authClient.post('/member/insights',{
            'user_type':2,
            'dateRange':this.dates,
            'search' : this.search,
            'type' : type,
          }, {responseType:  type == 0? '':'blob'}).then(response => {

            if(type == '1'){

              const href = window.URL.createObjectURL(response.data);

              const anchorElement = document.createElement('a');

              anchorElement.href = href;
              anchorElement.download = `${Date.now()}_member_insights.pdf`;
              document.body.appendChild(anchorElement);
              anchorElement.click();

              document.body.removeChild(anchorElement);
              window.URL.revokeObjectURL(href);

            }else{
              this.expences = response.data.result;
              this.total_profit = response.data.Total_amount
            
            }
            this.loading = false;
          });
        },
        downloadCsv() {
        // Replace this with your actual CSV data
          const csvData = this.expences.map(item => ({
            "Transaction Date & Time": item.created_at,
            Name: item.user.name,
            Amount: item.amount,
            Service: item.name,
            Description: item.description
          }));;

          const csv = Papa.unparse(csvData);

          const blob = new Blob([csv], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);

          // Create a temporary anchor element and trigger the download
          const a = document.createElement('a');
          a.href = url;
          a.download = `${Date.now()}_expences_insights.csv`;
          document.body.appendChild(a);
          a.click();

          // Clean up
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
      },
      closeDialogs() {
        this.addFoodDialog = false;
        this.getExpences();
      }
    },
    mounted() {
      this.dates = [new Date().toISOString().split('T')[0]]
      this.getExpences();
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  