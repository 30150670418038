import { render, staticRenderFns } from "./GymFinances.vue?vue&type=template&id=33f9a03c&scoped=true&"
import script from "./GymFinances.vue?vue&type=script&lang=js&"
export * from "./GymFinances.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f9a03c",
  null
  
)

export default component.exports