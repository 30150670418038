<template>
  <v-app v-if="$store.state.user != null">
    <v-app-bar v-if="$route.path !== '/auth'" flat app>
      
      <template v-if="$route.path == '/gyms/365/details-view' ">    
        <v-btn v-if="$route.fullPath.split('/').length > 2 && !not_include.includes($route.fullPath)  && this.$store.state.user.role !== 'gym'" icon @click="$router.back()" color="black">
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-card-title>{{ $route.name }}</v-card-title>
        <v-spacer/>
        <v-col cols="auto">
          <span class="font-weight-bold">North Star Fitness Vinares 09 </span> <br>
          Fitness Center
        </v-col>
        <v-divider class="mr-2" vertical/>
        <v-avatar>
          <v-img :src="$store.state.s3BaseUrl + $store.state.user.avatar_url"/>
        </v-avatar>
        <v-btn color="red" icon @click="$store.commit('deAuth')">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>

      <template v-else>    
        <v-btn v-if="$route.fullPath.split('/').length > 2 && !not_include.includes($route.fullPath)  && this.$store.state.user.role !== 'gym'" icon @click="$router.back()" color="black">
          <v-icon>mdi-arrow-left-circle</v-icon>
        </v-btn>
        <v-card-title>{{ $route.name }}</v-card-title>
        <v-spacer/>
        <v-col cols="auto">
          <span class="font-weight-bold">{{  $store.state.user.name }}</span> <br>
          {{  $store.state.user.role.toUpperCase() }}
        </v-col>
        <v-divider class="mr-2" vertical/>
        <v-avatar>
          <v-img :src="$store.state.s3BaseUrl + $store.state.user.avatar_url"/>
        </v-avatar>
        <v-btn color="red" icon @click="$store.commit('deAuth')">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </template>
      
    </v-app-bar>

    <v-navigation-drawer  v-if="$route.path !== '/auth' && ['admin'].includes($store.state.user.role)" width="280" color="#f2f2f2" app permanent class="pr-4">
      <router-link :to="'/'" class="router--">
        <div class="d-flex justify-center">
          <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
        </div>
      </router-link>

      <div v-for="(menu,i) in sideMenu" :key="i">

        <v-list-item v-if="menu.sub_menu.length == 0" active-class="sideMenu-active"  color="transparent"
                   class="sideMenu rounded" :to="menu.to">
          <v-list-item-icon>
            <v-icon color="black">{{ menu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
        </v-list-item>

        <v-list-group v-else>
            
            <template v-slot:activator>
              <v-list-item-icon>
                  <v-icon color="black">{{ menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
            </template>
          
            <v-list-item active-class="subMenusideMenu-active" v-for="sublink in menu.sub_menu"  color="transparent"
                      class="subMenusideMenu rounded ml-5" :to="sublink.to">
              <v-list-item-title class="black--text">{{ sublink.title }}</v-list-item-title>
            </v-list-item>

        </v-list-group>

      </div>

    </v-navigation-drawer>

    <v-navigation-drawer  v-if="$route.path !== '/auth' && ['moderator'].includes($store.state.user.role)" width="280" color="#f2f2f2" app permanent class="pr-4">
        <div class="d-flex justify-center">
          <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
        </div>

      <v-list-item v-if="shouldRestrict(menu)" active-class="sideMenu-active" v-for="(menu,i) in webManager" :key="i" color="transparent"
                   class="sideMenu rounded" :to="menu.to">
        <v-list-item-icon>
          <v-icon color="black">{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>


    <v-navigation-drawer v-if="$route.path !== '/auth' && $store.state.user.role === 'therapy'" width="280" color="#f2f2f2" app permanent class="pr-4">
      <div class="d-flex justify-center">
        <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
      </div>

      <v-list-item active-class="sideMenu-active" v-for="(menu,i) in sideMenuTherapy" :key="i" color="transparent"
                   class="sideMenu rounded" :to="menu.to">
        <v-list-item-icon>
          <v-icon color="black">{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>


    <v-navigation-drawer v-if="$route.path !== '/auth' && $store.state.user.role === 'gym' && $store.state.gymType === 'exclusive'" width="280" color="#f2f2f2" app permanent class="pr-4">
      <div class="d-flex justify-center">
        <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
      </div>

      <v-list-item active-class="sideMenu-active" v-for="(menu,i) in sideMenuExGym" :key="i" color="transparent"
                   class="sideMenu rounded" :to="menu.to">
        <v-list-item-icon>
          <v-icon color="black">{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>

    <v-navigation-drawer v-if="$route.path === '/gyms/365/details-view' " width="280" color="#f2f2f2" app permanent class="pr-4">
      <div class="d-flex justify-center">
        <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
      </div>

      <v-list-item active-class="sideMenu-active" v-for="(menu,i) in sideMenuGymView" :key="i" color="transparent"
                   class="sideMenu rounded" :to="menu.to">
        <v-list-item-icon>
          <v-icon color="black">{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>

    <v-navigation-drawer v-else-if="$route.path !== '/auth' && $store.state.user.role === 'gym' && $store.state.gymType === 'normal'" width="280" color="#f2f2f2" app permanent class="pr-4">
      <div class="d-flex justify-center">
        <v-img height="128" width="260" contain src="@/assets/allblack.png" max-width="200" max-height="200"></v-img>
      </div>

      <v-list-item active-class="sideMenu-active" v-for="(menu,i) in sideMenuGym" :key="i" color="transparent"
                   class="sideMenu rounded" :to="menu.to">
        <v-list-item-icon>
          <v-icon color="black">{{ menu.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="black--text">{{ menu.title }}</v-list-item-title>
      </v-list-item>
    </v-navigation-drawer>  
    
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {authClient} from "@/plugins/http";
export default {
  name: 'App',

  data: () => ({
    pathForGym:'',
    not_include:['/faq/add', '/faq/sub','/faq/main'],
    restrictedItems: [
      '/manage-users',
      '/subscriptions',
      '/commercial-gyms',
      '/commercial-gyms',
      '/members',
      '/trainers',
      '/doctors'
    ],
    sideMenuExGym:[
      {
        title: 'Gym Manager',
        icon: 'mdi-dumbbell',
        to: '',
      },
      {
        title: 'Finances',
        icon: 'mdi-account-group',
        to: ''
      }
    ],sideMenuGym:[
      {
        title: 'Gym Manager',
        icon: 'mdi-dumbbell',
        to: ''
      },
      {
        title: 'Finances',
        icon: 'mdi-account-group',
        to: ''
      },
      {
        title: 'Exercise Bank',
        icon: 'mdi-dumbbell',
        to: '/gyms/exercise-bank'
      },
      
      {
        title: 'Preset Create',
        icon: 'mdi-weight-lifter',
        to: ''
      }
      ,
      {
        title: 'Subscribed Users',
        icon: 'mdi-account-multiple-check',
        to: '/gym-user-manage'
      }
    ],
    sideMenuGymView:[
      {
        title: 'Gym Manager',
        icon: 'mdi-dumbbell',
        to: '/gyms/365/details-view'
      }
    ],
    sideMenuTherapy:[
      {
        title: 'Therapy Profile',
        icon: 'mdi-account',
        to: '/therapy-profile'
      },
      {
        title: 'Meetings',
        icon: 'mdi-account-group',
        to: '/therapy-meetings'
      },
    ],
    sideMenu: [],
    webManager: [
      {
        title: 'Manage Breadcrumbs',
        icon: 'mdi-navigation-variant-outline',
        to: '/home-settings'
      },
      {
        title: 'Success Story Manage',
        icon: 'mdi-account-check',
        to: '/success-story'
      },
      {
        title: 'Previous Project Manage',
        icon: 'mdi-adjust',
        to: '/previous-projects'
      },
      {
        title: 'Trainer Appointments',
        icon: 'mdi-account-arrow-up-outline',
        to: '/appointments'
      },
      {
        title: 'Web Subscriptions',
        icon: 'mdi-text-long',
        to: '/web-subscriptions'
      },

    ]
  }),
  methods:{
    shouldRestrict(item){
      if(this.$store.state.user.role === 'admin'){
        return true
      } else {
        if(this.restrictedItems.includes(item.to)){
          return false
        } else {
          return true
        }
      }
    },

    laodSidebar(){

      if(  this.$store.state.user.role !== 'admin'  ){
        return;
      }

        this.loading = true;
        authClient.get('/sidebar-access/load-sidebar').then(res=>{
          this.$store.state.sideBar = res.data
          this.viewMenuAccessedItems();
          this.loading = false;
        }).catch(err=>{
          this.loading = false;
        })
    },
    
    
  },  watch: {
    // Watch for changes in the Vuex store's sideBar state
    '$store.state.sideBar': {
      handler(newVal) {
        this.sideMenu = newVal; // Update sideMenu when the state changes
      },
      deep: true, // (Optional) To detect changes within nested objects
      immediate: true // (Optional) Will trigger the watch handler immediately on component mount
    }
  },
  created() {
    // Set the initial value of sideMenu when the component is created
    this.sideMenu = this.$store.state.sideBar;
  },
  mounted() {
      this.laodSidebar();
      this.sideMenu = this.$store.state.sideBar;
      this.sideMenuExGym[0].to = '/gyms/' + this.$store.state.user.id + '/details';
      this.sideMenuExGym[1].to = '/gyms/' + this.$store.state.user.id + '/finances';
      this.sideMenuGym[0].to = '/gyms/' + this.$store.state.user.id + '/details';
      this.sideMenuGym[1].to = '/gyms/' + this.$store.state.user.id + '/finances';
      this.sideMenuGym[3].to = '/gyms/' + this.$store.state.user.id + '/preset-manage';
      this.sideMenuGym[5].to = '/gyms/' + this.$store.state.user.id + '/user-workouts';
  },
  beforeMount() {
    this.$store.commit('setLocalAuth');
  },
};
</script>

<style scoped lang="scss">
.sideMenu {
  transition: all 0.5s ease;
  border-radius: 32px !important;
  margin: 4px !important;
}

.sideMenu:hover {
  background: #ffa200;
  transition: all 0.5s ease;
  border-radius: 32px !important;
  margin: 4px !important;
}

.sideMenu-active {
  background: #ffa200;
  border-radius: 32px !important;
  transition: all 0.5s ease;
  margin: 4px !important;
}

.subMenusideMenu{
    transition: all 0.5s ease;
    border-radius: 32px !important;
    margin-top: 4px !important;
}


.subMenusideMenu:hover {
  background: #ffa200;
  transition: all 0.5s ease;
  border-radius: 32px !important;
  margin-top: 4px !important;
}

.subMenusideMenu-active {
  background: #ffa200;
  border-radius: 32px !important;
  transition: all 0.5s ease;
  margin-top: 4px !important;
}

</style>
