import axios from 'axios';
import iziToast from 'izitoast';
import router from "@/router/index.js";


//Authed
export let authClient = axios.create({});
authClient.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
authClient.defaults.baseURL = 'https://api.northstar.mv/api';
authClient.interceptors.response.use(undefined, function (error) {
    

    if(error.response.status === 401) {
        window.localStorage.clear();
        router.push('/auth');
        return Promise.reject(error);
    }

    if(error.response.status === 422) {
        console.log(error);

        try {
            iziToast.error({
                title: 'error',
                message: error.response.data[Object.keys(error.response.data)[0]][0] 
            });
        } catch (error_) {
            console.log("dadaad");
            iziToast.error({
                title: 'error',
                message: error.response.data.message 
            });
        }
        
    }else if(error) {
        console.log(error.response);
        iziToast.error({
            title: 'error',
            message: error.response.data.message,
        });
    }
});

// NoneAuthed
export let client = axios.create({});
client.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
client.defaults.baseURL = 'https://api.northstar.mv/api';

export default {
    authClient,
    client
}
