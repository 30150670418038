<template>
  <v-container>
    <v-row no-gutters class="mb-4">
      <v-card outlined hover ripple dark class="green mr-4" width="180">
        <v-card-subtitle class="pb-0">All Trainers</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ trainerCount }}</v-card-title>
      </v-card>
      <v-card outlined hover ripple dark class="warning" width="180">
        <v-card-subtitle class="pb-0">Pro Trainers</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ proTrainerCount  }}</v-card-title>
      </v-card>
      <v-card outlined hover ripple dark class="green ml-4 mr-4" width="180">
        <v-card-subtitle class="pb-0">Active Coaches</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ active_coach_count }}</v-card-title>
      </v-card>
      <v-card outlined hover ripple dark class="warning" width="180">
        <v-card-subtitle class="pb-0">Disabled Coaches</v-card-subtitle>
        <v-card-title class="pt-0 text-h4">{{ disabled_coach_count }}</v-card-title>
      </v-card>
      <v-spacer></v-spacer>
      <v-btn to="/coaches/insights" class="ml-2" rounded height="56" elevation="0" dark>
        Coaches Revenue
        </v-btn>
    </v-row>
    
    <v-row gutters>
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
      <div>
    <v-text-field v-model="search" outlined class="rounded-lg" label="Search Members..." append-icon="mdi-magnify" @keyup.enter="getTrainers()" @click:append="getTrainers()"></v-text-field>
    </div>
        </v-col>
        <v-col
              class="pb-0"
              cols="12"
              md="6"
              lg="4"
        >
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="rounded-lg"
                outlined
                readonly
                v-model="dateRangeText"
                label="Date Range"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="dates= []"
              >
                Reset
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(date);getTrainers()"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col
              cols="12"
              md="3"
              lg="2"
        >
              <v-btn
              @click="getTrainers(1)"
              :style="{ width: '100%' }"
                x-large
                color="warning"
                dark
              :loading="loading"
              >
              <v-icon
                left
                dark
              >
              mdi-tray-arrow-down
              </v-icon>
                Export Pdf
              </v-btn>
        </v-col>
        <v-col
              cols="12"
              md="3"
              lg="2"
        >
              <v-btn
              @click="downloadCsv()"
              :loading="loading"
              :style="{ width: '100%' }"
                x-large
                color="warning"
                dark
              >
              <v-icon
                left
                dark
              >
              mdi-tray-arrow-down
              </v-icon>
                Export Csv
              </v-btn>
        </v-col>
      </v-row>
      <v-row gutters class="mt-0">
        <v-col
              class="pb-0"
              cols="12"
              md="6"
              lg="4"
        >
        <v-select @change="getTrainers()" :items="membershipType" v-model="memberShip" class="rounded-lg" item-text="text" item-value="value"  outlined label="Membership"/>
      </v-col>
      </v-row>

    <v-card outlined :loading="loading">
      <v-data-table
          :headers="headers"
          :items="trainers">
        <template v-slot:item.avatar_url="{ item }">
          <v-avatar class="ma-2">
            <v-img :src="$store.state.s3BaseUrl + item.avatar_url"></v-img>
          </v-avatar>
        </template>
        <template v-slot:item.membership="{ item }">
          <v-chip dark :color="hasActiveSub(item) ? '#ffa200':''">{{ hasActiveSub(item) ? 'PRO Member' : 'Member' }}</v-chip>
        </template>

        <template v-slot:item.subscriptions="{ item }">
          {{ ValidDate(item)  }}
        </template>

        <template v-slot:item.status="{ item }">
            <v-chip
              class="ma-2"
              :color=" item.status == 1 ? 'success': 'warning'"
            >
            {{ item.status == 1 ? 'Active' : 'Disabled' }}
            </v-chip>
          </template>
        
          <template v-slot:item.created_at="{ item }">
            {{  new Date(item.created_at).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                })
              }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn @click="statusChange(item.id, item.status == 1? 2 : 1)" icon>
            <v-icon :color=" item.status == 2 ? 'success': 'warning'"> {{ item.status == 1 ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="viewDetails(item)" v-bind="attrs"
                     v-on="on" icon>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>

            </template>
            <span>More Info</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";
import Papa from 'papaparse';

export default {
  name: "Trainers",
  data: () => ({
    search: '',
    date:null,
    menu: false,
    modal: false,
    menu2: false,
    memberShip:'0',
    membershipType: [
        {text: 'All', value: '0'},
        {text: 'Pro', value: '1'},
        {text: 'Member', value: '2'}
      ],
    dates: [],
    trainerCount:0,
    proTrainerCount:0,
    trainers: [],
    loading: true,
    active_coach_count:0,
    disabled_coach_count:0,
    headers: [
      {text: "DP", value: "avatar_url", sortable: false},
      {text: "User ID", value: "id"},
      {text: "Reg. Date", value: "created_at"},
      {text: "Name", value: "name"},
      {text: "Email", value: "email"},
      {text: "Phone", value: 'phone'},
      {text: "Country", value: 'country_code'},
      {text: "Membership", value: 'membership',sortable: false},
      {text: "Valid till", value: 'subscriptions',sortable: false},
      {text: "Status", value: 'status',sortable: true},
      {text: "Actions", value: "actions", sortable: false}
    ],
  }),
  computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
  methods: {
    viewDetails(item) {
      console.log(item);
      this.$router.push({name: 'Trainer Profile Overview', params: {member: item}});
    },
    hasActiveSub(item) {
      if (item.subscriptions === null) {
        return false;
      } else {
        return item.subscriptions.is_active
      }
    },
    ValidDate(item) {
      if (item.subscriptions === null) {
        return 'N/A';
      } else {
        
        var options = { 
          year: 'numeric', 
          month: 'short', 
          day: 'numeric' 
        };
        
        if(item.subscriptions.is_active){
          const currentDate = new Date();
          const newDate = new Date(item.subscriptions.valid_till);
          const differenceMs = newDate - currentDate;
          const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
          return `${newDate.toLocaleDateString('en-GB', options)} (${daysDifference} days left)`;
        }else{
          return 'Expired'
        }
      }
    },
    statusChange(id, status) {
        authClient.post('/status/change',{
            status: status,
            tableId: 1,
            resultId: id
          }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
          this.getTrainers()
          this.getCounts()
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          }).catch((error) => {
            console.log(error);
          });
      },
    getTrainers(type=0) {
      this.loading = true;
      authClient.post('/trainers/get-all',{
        'dateRange':this.dates,
        'membership' : this.memberShip,
        'search' : this.search,
        'type' : type,
      }, {responseType:  type == 0? '':'blob'}).then(response => {

        if(type == '1'){

          const href = window.URL.createObjectURL(response.data);

          const anchorElement = document.createElement('a');

          anchorElement.href = href;
          anchorElement.download = `${Date.now()}_trainer_insights.pdf`;
          document.body.appendChild(anchorElement);
          anchorElement.click();

          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);

        }else{
          this.trainers = response.data;
         
        }
        this.loading = false;
      });
    },
    getCounts() {
      this.loading = true;
      authClient.get('/dashboard/counts').then(response => {
        this.trainerCount = response.data.trainer_count;
        this.proTrainerCount = response.data.pro_trainer_count;
        this.disabled_coach_count = response.data.disabled_coach_count;
        this.active_coach_count = response.data.active_coach_count;
        this.loading = false;
        console.log('NEW');
        console.log(response.data);
      }).catch(error => {
        console.log(error);
        this.loading = false;
      });
    },
    downloadCsv() {
      // Replace this with your actual CSV data
      const csvData = this.trainers.map(item => ({
        UserId: item.id,
        RegDate: item.created_at,
        Name: item.name,
        Nationality: item.country_code,
        Gender: item.gender,
        Email: item.email,
        Phone: item.phone,
        MemberType: item.subscription ? 'Pro' : 'Member',
        }));;

        const csv = Papa.unparse(csvData);

        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element and trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${Date.now()}_trainer_insights.csv`;
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
  },
  mounted() {
    this.getTrainers();
    this.getCounts()
  }
}
</script>

<style scoped>

</style>
